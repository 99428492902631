'use client'

import type { ComponentPropsWithRef, PropsWithChildren } from 'react'
import { useMemo } from 'react'

import { useConfig } from '@features/common.hooks/useConfig'
import classNames from 'classnames'
import NextLink from 'next/link'

export interface LinkProps extends Omit<ComponentPropsWithRef<'a'>, 'href'> {
  className?: string
  path: string
  type?: 'console' | 'faucet' | 'internal' | 'external'
}
export const Link: React.FC<PropsWithChildren<LinkProps>> = ({
  className,
  path,
  type = 'internal',
  children,
  target,
  ...props
}) => {
  const config = useConfig()

  const url = useMemo(() => {
    switch (type) {
      case 'console': {
        return new URL(path, config.urls.console).href
      }
      case 'faucet': {
        return new URL(path, config.urls.faucet).href
      }
      case 'internal':
      case 'external':
        return path
    }
  }, [config, path, type])

  return (
    <NextLink
      className={classNames(
        'text-interactive font-circular-medium hover:underline',
        className,
      )}
      href={url}
      rel="noreferrer"
      target={
        target !== undefined ? target : type === 'internal' ? '_self' : '_blank'
      }
      {...props}
    >
      {children}
    </NextLink>
  )
}
